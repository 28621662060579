import React from 'react'
import { ICustomerStoryCard } from '../../../../types/resources'
import { useLocale } from '../../../../hooks/useLocale'
import { Link } from 'gatsby'
import { Box, Flex, Heading, Icons, Image, Text } from '../../../../atoms'
import { Tag } from '../../../../atoms/Tags'
import { CustomerStoryCardWrapper } from './CustomerStoryCard.styled'

function CustomerStoryCard(props: ICustomerStoryCard): JSX.Element {
  const { name, pageInfo, excerpt, thumbnail } = props

  const { localeSlug } = useLocale()

  return (
    <Link to={`/${localeSlug}${pageInfo.slug}`} style={{ height: '100%', display: 'block' }}>
      <CustomerStoryCardWrapper
        position={'relative'}
        flexDirection={'column'}
        justifyContent={'flex-end'}
        alignItems={'stretch'}
        p={[5, 5, 6]}
        borderRadius={8}
        borderStyle={'solid'}
        borderWidth={'1px'}
        borderColor={'black'}
        width={'100%'}
        height={'100%'}
        backgroundColor={'black'}
        overflow={'hidden'}
      >
        {thumbnail && (
          <Box position={'absolute'} top={0} left={0} width={'100%'} height={'100%'} zIndex={0}>
            <Image
              alt={thumbnail.alt ?? ''}
              image={thumbnail.gatsbyImageData}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        )}
        <Flex
          flex={'0 0 auto'}
          position={'relative'}
          flexDirection={'column'}
          alignItems={'stretch'}
          justifyContent={'space-between'}
          zIndex={2}
        >
          <Box flex={'1'}>
            <Box mb={3}>
              <Tag label={'Customer Story'} color={'green'} />
            </Box>
            <Heading as={'h3'} type={'heading3'} color={'white'}>
              {name}
            </Heading>

            <Box mt={3}>
              <Text type={'small'} color={'white'}>
                {excerpt}
              </Text>
            </Box>
          </Box>

          <Flex flex={'0 0 auto'} mt={4} justifyContent={'flex-end'}>
            <Icons.ArrowRight color={'white'} />
          </Flex>
        </Flex>
      </CustomerStoryCardWrapper>
    </Link>
  )
}

export default CustomerStoryCard
