import styled from 'styled-components'
import { Flex } from '../../../../atoms'

export const CustomerStoryCardWrapper = styled(Flex)`
  aspect-ratio: 0.75;

  &:before {
    content: '';
    position: absolute;
    display: block;
    inset: 60% 0 0;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    z-index: 1;
    transition: transform 1s ease-in-out;
    transform-origin: bottom center;
  }

  &:hover {
    &:before {
      transform: scale(1.1);
    }
  }
`
