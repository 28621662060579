import React from 'react'
import { ICustomerStoryCard } from '../../../../types/resources'
import { useLocale } from '../../../../hooks/useLocale'
import { Link } from 'gatsby'
import { Box, Button, Flex, Heading, Image, Text } from '../../../../atoms'
import { Tag } from '../../../../atoms/Tags'
import { CustomerStoryFeaturedCardThumbWrapper } from './CustomerStoryFeaturedCard.styled'

function CustomerStoryFeaturedCard(props: ICustomerStoryCard): JSX.Element {
  const { name, pageInfo, excerpt, thumbnail } = props

  const { localeSlug } = useLocale()

  return (
    <Link to={`/${localeSlug}${pageInfo.slug}`} style={{ height: '100%', display: 'block' }}>
      <Flex flexWrap={['wrap', 'wrap', 'nowrap']} style={{ gap: '16px' }}>
        <CustomerStoryFeaturedCardThumbWrapper
          flex={['0 0 100%', '0 0 100%', '0 0 calc(50% - 16px)']}
          borderRadius={8}
          overflow={'hidden'}
        >
          {thumbnail && (
            <Image
              alt={thumbnail.alt ?? ''}
              image={thumbnail.gatsbyImageData}
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </CustomerStoryFeaturedCardThumbWrapper>

        <Box flex={['0 0 100%', '0 0 100%', '1']} pl={[0, 0, 4]}>
          <Box mb={3}>
            <Tag label={'Customer Story'} color={'green'} />
          </Box>
          <Heading as={'h3'} type={'heading3'}>
            {name}
          </Heading>

          <Box mt={3}>
            <Text type={'small'}>{excerpt}</Text>
          </Box>

          <Box mt={3}>
            <Button buttonType={'text'}>{'Learn more'}</Button>
          </Box>
        </Box>
      </Flex>
    </Link>
  )
}

export default CustomerStoryFeaturedCard
