import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import { mediaQueries } from '../../../theme'

export const FeaturedCustomerStoriesRow = styled(Row)`
  flex-wrap: nowrap;
  overflow: auto;
  padding-left: 0.7rem;
  padding-right: 0.7rem;

  ${mediaQueries.greaterThan('md')`
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  `}
`

export const FeaturedCustomerStoriesCol = styled(Col)`
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  ${mediaQueries.greaterThan('md')`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `};
`
