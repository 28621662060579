import React from 'react'
import { Link } from 'gatsby'
import { ICustomerStory } from '../../../../types/resources'
import { ButtonProps } from '../../../../types/buttons'
import { PageInfoLinkProps } from '../../../../types/pages'
import { Box, Flex, Heading, Image, Tag, TemplateTag, Text } from '../../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { StructuredTextContent } from '../../../commons/StructuredText/StructuredTextContent'
import { SECTION_BOTTOM_PADDING, SECTION_MARGIN, SECTION_TOP_PADDING } from '../../../../utils/constants'
import StructuredTextSummary from '../../../commons/StructuredTextSummary/StructuredTextSummary'
import PostShare from '../../../blog/PostShare/PostShare'
import { getUrlToShare } from '../../../../utils/commons'
import PostSide from '../../../blog/PostSide/PostSide'
import { useLocale } from '../../../../hooks/useLocale'
import { StaticImage } from 'gatsby-plugin-image'

interface CustomerStoryArticleProps extends Pick<ICustomerStory, 'content' | 'tags' | 'name' | 'cover' | 'excerpt'> {
  tagLabel: string
  tagLink: PageInfoLinkProps
  articleSideHeadline: string
  articleSideButton: Array<ButtonProps>
  articleSideTableOfContentLabel: string
  articleSideShareLabel: string
}

const CustomerStoryArticle: React.FC<CustomerStoryArticleProps> = ({
  tagLabel,
  tagLink,
  content,
  tags,
  articleSideHeadline,
  articleSideButton,
  articleSideTableOfContentLabel,
  articleSideShareLabel,
  name,
  cover,
  excerpt,
}) => {
  const { localeSlug } = useLocale()

  return (
    <Box as={'section'} position={'relative'} mb={SECTION_MARGIN}>
      <Box
        as={'section'}
        position={'relative'}
        backgroundColor={'violetLight'}
        pt={SECTION_TOP_PADDING}
        pb={SECTION_BOTTOM_PADDING}
        mb={SECTION_MARGIN}
      >
        <Box position={'absolute'} zIndex={0} top={0} left={0} width={'100%'} height={'100%'} overflow={'hidden'}>
          <Box display={['none', 'none', 'block']} position={'absolute'} left={-160} top={-160} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../../images/ellipse_violet_tbr.png'}
              alt={''}
              placeholder={'none'}
              width={400}
            />
          </Box>
          <Box display={['none', 'none', 'block']} position={'absolute'} left={0} bottom={0} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../../images/grid_synapse.png'}
              alt={''}
              placeholder={'none'}
              width={247}
            />
          </Box>
          <Box display={['none', 'none', 'block']} position={'absolute'} right={-160} bottom={-160} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../../images/ellipse_violet_grid.png'}
              alt={''}
              placeholder={'none'}
              width={400}
            />
          </Box>
        </Box>
        <Box position={'relative'} zIndex={1}>
          <Grid>
            <Row end={'xs'} middle={'xs'} reverse>
              {cover && (
                <Col xs={12} md={6}>
                  <Box
                    mb={[6, 8, 0]}
                    backgroundColor={'white'}
                    borderRadius={8}
                    overflow={'hidden'}
                    style={{ aspectRatio: '1.625' }}
                  >
                    <Image
                      alt={cover.alt ?? ''}
                      image={cover.gatsbyImageData}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                </Col>
              )}
              <Col xs={12} md={6}>
                <Box mb={4}>
                  <Link to={`/${localeSlug}${tagLink.slug}`}>
                    <Tag color='green' label={tagLabel} />
                  </Link>
                </Box>
                <Heading type={'heading1'}>{name}</Heading>
                {excerpt && (
                  <Box mt={5}>
                    <Text type={'large'}>{excerpt}</Text>
                  </Box>
                )}
              </Col>
            </Row>
          </Grid>
        </Box>
      </Box>

      <Grid>
        <Row>
          <Col xs={12} md={8}>
            <Box mt={[6, 7, 8]}>
              <StructuredTextContent content={content} />
            </Box>
            {tags.length > 0 && (
              <Flex flexWrap={'wrap'} gap={16} mt={[6, 7, 8]}>
                {tags.map((tag) => (
                  <TemplateTag key={tag.id} label={tag.name} />
                ))}
              </Flex>
            )}
          </Col>
          <Col xs={false} md={4}>
            <Flex flexDirection={'column'} alignItems={'stretch'} justifyContent={'space-between'} height={'100%'}>
              <Box flex={1} pb={32}>
                <Box position={'sticky'} top={'calc(90px + var(--banner-height))'}>
                  <StructuredTextSummary tableOfContentLabel={articleSideTableOfContentLabel} {...content} />
                  <Box mt={24}>
                    <PostShare label={articleSideShareLabel} urlToShare={getUrlToShare()} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <PostSide headline={articleSideHeadline} button={articleSideButton} />
              </Box>
            </Flex>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}
export default CustomerStoryArticle
