import React from 'react'
import { SectionFeaturedCustomerStories } from '../../../types/sections'
import { Box, Button, Flex, Heading } from '../../../atoms'
import { SECTION_MARGIN } from '../../../utils/constants'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { FeaturedCustomerStoriesCol, FeaturedCustomerStoriesRow } from './FeaturedCustomerStories.styled'
import { CustomerStoryCard } from '../../resources/customer-stories'

function FeaturedCustomerStories(props: SectionFeaturedCustomerStories): JSX.Element {
  const { sectionId, id, headline, button, customerStories } = props
  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Flex
              justifyContent={'space-between'}
              width={'100%'}
              alignItems={['flex-start', 'flex-start', 'flex-end']}
              gap={16}
              flexDirection={['column', 'row', 'row']}
            >
              <Heading as='h2' type='heading1'>
                {headline}
              </Heading>
              {button && button[0] && (
                <Button buttonType={button[0].buttonType} link={button[0].link} label={button[0].label} />
              )}
            </Flex>
          </Col>
        </Row>
        <Box mt={[6, 7, 8]}>
          <FeaturedCustomerStoriesRow className={'noscrollBar'}>
            {customerStories.map((customerStory) => (
              <FeaturedCustomerStoriesCol key={customerStory.id} xs={10} sm={5} md={4}>
                <Box mb={[6, 7, 0]}>
                  <CustomerStoryCard {...customerStory} />
                </Box>
              </FeaturedCustomerStoriesCol>
            ))}
          </FeaturedCustomerStoriesRow>
        </Box>
      </Grid>
    </Box>
  )
}

export default FeaturedCustomerStories
